import { Card, CardBody, Table, TableCaption, TableContainer, Thead, Tbody, Th, Tr, Td, Input, Stack, Spinner, Radio, RadioGroup, Button, Box,
    Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody
 } from '@chakra-ui/react'
import { ArrowUpDownIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { useParams } from 'react-router-dom'
import Pagination from 'components/Pagination/Pagination'
import { useState, useEffect } from 'react'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { api } from 'apis/configs/axiosConfigs'


export const SupplyGap = (props) => {
    const { countingId } = useParams()
    const [ items, setItems ] = useState([])
    const [ countedItems, setCountedItems ] = useState({})
    const [ currentPage, setCurrentPage ] = useState(1)

    const [ isUpLoading, setIsUpLoading ] = useState(false)
    const [ isFileLoading, setFileLoading ] = useState(false)
    const [ keyBy, setKeyBy ] = useState('upc')

    const [ sortConfig, setSortConfig ] = useState({ sort: null, sortOrder: '' })

    const toggleSortOrder = (field) => {
        if (sortConfig.sort === field) {
            setSortConfig({
                ...sortConfig,
                sortOrder: sortConfig.sortOrder === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortConfig({
                sort: field,
                sortOrder: 'asc'
            })
        }
    }

    const handlePageChange = (data) => {
        fetchItems(data)
        setCurrentPage(data)
    }

    const [ headerRow, setHeaderRow ]  = useState([ 'upc', 'mkt', 'description', 'department', 'amount', 'price buy', 'price sell', 'total buy', 'total sell', 'counted', 'gap', 'money gap buy', 'money gap sell' ])

    const sortable = ['upc', 'mkt', 'description', 'department', 'amount', 'price_buy', 'price_sell']

    const dynamicHeaderRow = [ 'description', 'departament', 'price per unit', 'total price', 'estimated', 'gap', 'money gap' ]
    
    const formatRow = ({ upc, mkt_1, description, department, amount, price_buy, price_sell }, allCountedItems = null) => {
        const counted = allCountedItems ? allCountedItems[keyBy === 'upc' ? upc : mkt_1] : null
        const count = counted?.amount ?? 0
        const gap = count - amount
        return [ keyBy === 'upc' ? upc : counted?.upc, keyBy === 'mkt_1' ? mkt_1 : counted?.mkt_1, description, department, amount, price_buy, price_sell, price_buy ? (amount * price_buy).toFixed(2) : 0, price_sell ? (amount * price_sell).toFixed(0) : 0, count, gap, price_buy ? (gap * price_buy).toFixed(2) : 0, price_sell ? (gap * price_sell).toFixed(0) : 0]
    }

    const filtersConfig = [
        keyBy === 'upc' 
            ? {type: 'upc', placeholder: 'UPC', isSelect: false}
            : {type: 'mkt_1', placeholder: 'MKT', isSelect: false},
        {type: 'description', placeholder: 'Description', isSelect: false},
        {type: 'department', placeholder: 'Department', isSelect: false},
        {type: 'amount', placeholder: 'Amount', isSelect: false},
        {type: 'price_buy', placeholder: 'Price Buy', isSelect: false},
        {type: 'price_sell', placeholder: 'Price Sell', isSelect: false}
    ]

    const fetchItems = async (filters) => {
        api.request({
            url: `/counting/expectations/${countingId}`,
            method: 'GET',
            params: { page: currentPage, keyBy, ...filters, ...( sortConfig.sort && sortConfig  ) }
        }).then( (resp) => {
            setItems(resp.data)
            
            resp.data.counted_items?.length !== 0 && setCountedItems(resp.data.counted_items)
            
            if (resp.dynamicHeaderRow) {
                setHeaderRow((headerRow) => [...headerRow, ...resp.dynamicHeaderRow])
            }
        })
    }

    const upload = async (event) => {
        if(!event.target.files.length) return
        setIsUpLoading(true)
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        formData.append('keyBy', keyBy)
        api.request({
            url: `/counting/expectations/upload/${countingId}`,
            method: 'POST',
            data: formData,
        }).then( (resp) => {
            fetchItems()
            
        }).finally(() => {
            setIsUpLoading(false)
        })
    }

    useEffect(() => {
        fetchItems()
    }, [keyBy, sortConfig])

    const downloadFile = async () => {
        setFileLoading(true)

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Data')
        worksheet.addRow(headerRow)

        const allItems = await api.request({
            url: `/counting/expectations/${countingId}/download`,
            method: 'GET',
            params: { keyBy }
        })

        allItems.data.data.forEach(item => worksheet.addRow(formatRow(item, allItems.data.counted_items)))
        workbook.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer], 
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'supply_gap.xlsx')
        )
        setFileLoading(false)
    }

    return (
        <Card>
            <CardBody>
                <Stack direction='row' spacing={4} mb={4}>
                    <RadioGroup onChange={setKeyBy} value={keyBy}>
                        <Stack direction='row' spacing={4}>
                            <Radio value='upc'>UPC</Radio>
                            <Radio value='mkt_1'>MKT</Radio>
                        </Stack>
                    </RadioGroup>
                    <Input type='file' onChange={upload} />
                    <Spinner size='sm' color='blue.500' hidden={!isUpLoading}/>
                    <Button onClick={downloadFile} size='sm' colorScheme='teal' mr='1' isLoading={isFileLoading} loadingText='Downloading...'>Download</Button>
                </Stack>
                <TableContainer>
                    <Table>
                        {/* <TableCaption placement='top'>Supply Gap</TableCaption> */}
                        <Thead>
                            <Tr>
                                {filtersConfig.map((filter, idx) => <> 
                                    { keyBy !== 'upc' && idx === 0 && <Th key={idx+'-'}><div></div></Th> } 
                                    <Th key={'h'+ idx}>
                                        <Input placeholder={filter.placeholder} onChange={(event) => fetchItems({ [filter.type]: event.target.value })} />
                                    </Th>
                                    { keyBy === 'upc' && idx === 0 && <Th key={idx+'-'}><div></div></Th> }
                                    </>
                                )}
                            </Tr>
                            <Tr>
                                { headerRow.map((header, idx) => (
                                     <Th key={'h-' + idx}> { sortable.includes(header) 
                                        ? <Box onClick={() => toggleSortOrder(header)} style={{cursor: 'pointer'}}>
                                            {header}
                                            {sortConfig.sort === header ? (
                                                sortConfig.sortOrder === 'asc' ? <ChevronDownIcon boxSize={4}/> : <ChevronUpIcon boxSize={4}/>
                                            ) : <ArrowUpDownIcon boxSize={4} />}
                                        </Box>
                                        : header }
                                     </Th>
                                )) }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {items.data?.map((item) => 
                                <Tr key={item._id}> 
                                    { formatRow(item, countedItems).map((cell, idx) => idx !== (keyBy === 'upc' ? 0 : 1)  ?
                                    <Td key={item._id + '_' + idx}>{cell}</Td> :
                                    <Td key={item._id + '_' + idx}>
                                        <Popover key={'pop' + item._id + idx} placement='right'>
                                            <PopoverTrigger>
                                                <Box
                                                    tabIndex='0'
                                                    role='button'
                                                    aria-label='Some box'
                                                    p={5}
                                                    // w='120px'
                                                    // bg='gray.300'
                                                >{cell}</Box>
                                            </PopoverTrigger>
                                            <PopoverContent w='400' bgGradient='linear(to-t, green.50, green.700)'>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverHeader>Rows</PopoverHeader>
                                                <PopoverBody>
                                                    <p>{countedItems[cell]?.row_names.join(', ')}</p>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Td>)
                                        
                                    }
                                </Tr>)}
                        </Tbody>
                    </Table>
                    <Pagination lastPage={items.last_page ?? 1 } getData={handlePageChange} />
                </TableContainer>
            </CardBody>
        </Card>
    )
}